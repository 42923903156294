import "./App.css";
import "./styles/TestingStyles1.css";
import "./styles/TestingStyles2.css";

import Routes from "./routes/Routes";

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
