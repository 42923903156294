import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../components/Common/models/Loader/Loader";

// public routes
const Login = lazy(() => import("../components/layouts/auth/Login"));
const ForgetPassword = lazy(() =>
  import("../components/layouts/auth/ForgotPassword")
);
const OtpVerify = lazy(() => import("../components/layouts/auth/OtpVerify"));
const ResetPassword = lazy(() =>
  import("../components/layouts/auth/ResetPassword")
);
const Home = lazy(() => import("../components/layouts/frontend/Home"));
const Register = lazy(() => import("../components/layouts/auth/Register"));
const Communities = lazy(() =>
  import("../components/layouts/frontend/Communities")
);
const CommunityDetail = lazy(() =>
  import("../components/layouts/frontend/CommunityDetail")
);

// dahbaord routes
const Dashboard = lazy(() =>
  import("../components/layouts/dashboard/Dashboard")
);
const Profile = lazy(() => import("../components/layouts/dashboard/Profile"));
const MyCommunities = lazy(() =>
  import("../components/layouts/dashboard/MyCommunities")
);
const EditProfile = lazy(() =>
  import("../components/layouts/dashboard/EditProfile")
);
const CreateCompetition = lazy(() =>
  import("../components/layouts/dashboard/CreateCompetition")
);

// admin routes
const Users = lazy(() =>
  import("../components/layouts/dashboard/Corporate/Users")
);
const AddUser = lazy(() =>
  import("../components/layouts/dashboard/Corporate/AddUser")
);
const AddCommunity = lazy(() =>
  import("../components/layouts/dashboard/Corporate/AddCommunity")
);
const CorporateCommunities = lazy(() =>
  import("../components/layouts/dashboard/Corporate/CorporateCommunities")
);
const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/verify-otp" element={<OtpVerify />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/communities" element={<Communities />} />
          <Route path="/community-detail/:id" element={<CommunityDetail />} />
          <Route path="/create-competition" element={<CreateCompetition />} />

          {/* dashbord routes */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/my-communities" element={<MyCommunities />} />

          {/* admin routes */}
          <Route path="/all-users" element={<Users />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/add-community" element={<AddCommunity />} />
          <Route path="/all-communities" element={<CorporateCommunities />} />

          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
